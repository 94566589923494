var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-0 pt-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Caderno de reposição e aula extra")])]),_c('v-card-subtitle',[_c('div',[_vm._v("A partir de 16/01/2024, as reposições serão exibidas como pagas no sistema.")])]),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('period-selector-component',{model:{value:(_vm.selectPeriodDate),callback:function ($$v) {_vm.selectPeriodDate=$$v},expression:"selectPeriodDate"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-select',{staticClass:"mt-5",attrs:{"items":_vm.tipoOptions,"label":"Filtrar por Tipo","clearable":"","dense":"","hide-details":""},model:{value:(_vm.selectedTipo),callback:function ($$v) {_vm.selectedTipo=$$v},expression:"selectedTipo"}})],1),_c('v-col',[_c('v-btn',{staticClass:"primary",on:{"click":function($event){return _vm.buscar()}}},[_vm._v(" buscar ")])],1)],1),_c('v-row',[_c('v-col',[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('div',[_c('v-text-field',{attrs:{"clearable":"","flat":"","hide-details":"","prepend-inner-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"footer-props":{ itemsPerPageOptions: [-1] },"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"dense":"","fixed-header":"","height":"50vw","hide-default-footer":"","loading-text":"Carregando...","multi-sort":""},on:{"click:row":_vm.iniciar_aula},scopedSlots:_vm._u([{key:"item.aluno_nome",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"color":"inherit"},attrs:{"href":("/main/Aluno/aluno/edit/" + (item.aluno_id)),"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(item.aluno_nome)+" ")])]}},{key:"item.data_hora",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeWithoutConvert")(item.data_hora))+" ")]}},{key:"item.financeiro_status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.financeiro_status)+" ")]}},{key:"item.aprovada",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',[_vm._v(" Sim ")]):_c('span',[_vm._v(" Não ")])]}},{key:"item.tipo",fn:function(ref){
var item = ref.item;
return [(item.tipo == 0)?_c('span',[_vm._v(" Reposição ")]):(item.tipo == 1)?_c('span',[_vm._v(" "+_vm._s(_vm.getNomeAulaExtraSingular())+" ")]):_c('span',[_vm._v(" Reposição em turma ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.tipo == 0)?_c('span',[(item.status == 0 && _vm.isNaoInformado(item))?_c('span',[_vm._v(" Não apontado ")]):(item.status == 0)?_c('span',[_vm._v(" Aula não lecionada ")]):_c('span',[_vm._v(" Aula lecionada ")])]):(item.tipo == 1)?_c('span',[(item.status == 1)?_c('span',[_vm._v(" Aguardando aprovação ")]):_vm._e(),(item.status == 2)?_c('span',[_vm._v(" Aprovação ")]):_vm._e(),(item.status == 3)?_c('span',[_vm._v(" Reprovado ")]):_vm._e(),(item.status == 4)?_c('span',[_vm._v(" Agendado ")]):_vm._e(),(item.status == 5)?_c('span',[_vm._v(" Desmarcado pelo Aluno ")]):_vm._e(),(item.status == 6)?_c('span',[_vm._v(" Falta ")]):_vm._e(),(item.status == 7)?_c('span',[_vm._v(" Presença ")]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.codigo_referencia_num",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"color":"inherit"},attrs:{"href":("/main/PedagogicoTurma/turma/edit/" + (item.turma_id)),"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(item.codigo_referencia_num)+" ")])]}}],null,true)})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }